jQuery(document).ready(function () {
    const
        searchActiveClass = 'search-active',
        $body = jQuery('body'),
        $container = jQuery('#search-container'),
        $input = jQuery('input[type="search"]'),
        $link = jQuery('#search-link'),
        $searchCloseLink = jQuery('#search-close-link')
    ;

    $link.on('click', e => {
        e.preventDefault();

        if (!$body.hasClass(searchActiveClass)) {
            $body.addClass(searchActiveClass);
            $container.show();
            jQuery(window).trigger('resize');
        }

        $input.focus();
    });

    $searchCloseLink.on('click', e => {
        e.preventDefault();

        jQuery('.wpdreams_asl_results').hide();
        $container.hide();
        jQuery(window).trigger('resize');
        $body.removeClass(searchActiveClass);
    });
});
